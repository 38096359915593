import fileDownload from 'js-file-download';
import _ from 'lodash';
import moment from 'moment';

import Constants from '../../../../../../constants';
import dollarsToCents from '../../../../common/dollars-to-cents';
import getPayableIndependentDoctors from '../../../../common/get-payable-independent-doctors';
import zeroFill from '../../../../common/zero-fill';
import convertLineToText from './convert-line-to-text';

const headerLine = {
  field01: '0',
  field02: '                 ',
  field03: '01',
  field04: 'NAB',
  field05: '       ',
  field06: 'Stepping Stones Medical Ce',
  field07: '486837',
  field08: 'DRInvoices  ',
  field09: 'DDMMYY',
  field10: '                                        ',
  field11: '\n',
};

const creditProviderLine = {
  field01: '1',
  field02: '000-000',
  field03: '123456789',
  field04: ' ',
  field05: '50',
  field06: '0000000000',
  field07: '                                ',
  field08: 'Stepping Stones   ',
  field09: Constants.BANKING.HOLDING_ACCT_BSB,
  field10: Constants.BANKING.HOLDING_ACCT_NO,
  field11: 'Stepping Stones 00000000',
  field12: '\n',
};

const providerFeeLine = {
  field01: '1',
  field02: Constants.BANKING.OPERATING_ACCT_BSB,
  field03: Constants.BANKING.OPERATING_ACCT_NO,
  field04: ' ',
  field05: '50',
  field06: '0000000000',
  field07: 'Stepping Stones Medical Centre  ',
  field08: 'Service Fee       ',
  field09: Constants.BANKING.HOLDING_ACCT_BSB,
  field10: Constants.BANKING.HOLDING_ACCT_NO,
  field11: 'Stepping Stones 00000000',
  field12: '\n',
};

const practiceLine = {
  field01: '1',
  field02: Constants.BANKING.OPERATING_ACCT_BSB,
  field03: Constants.BANKING.OPERATING_ACCT_NO,
  field04: ' ',
  field05: '50',
  field06: '0000000000',
  field07: 'Stepping Stones Medical Centre  ',
  field08: '                  ',
  field09: Constants.BANKING.HOLDING_ACCT_BSB,
  field10: Constants.BANKING.HOLDING_ACCT_NO,
  field11: 'Stepping Stones 00000000',
  field12: '\n',
};

const netDebitLine = {
  field01: '1',
  field02: Constants.BANKING.HOLDING_ACCT_BSB,
  field03: Constants.BANKING.HOLDING_ACCT_NO,
  field04: ' ',
  field05: '13',
  field06: '0000000000',
  field07: 'Stepping Stones Medical Centre  ',
  field08: 'Doctor Invoices   ',
  field09: Constants.BANKING.HOLDING_ACCT_BSB,
  field10: Constants.BANKING.HOLDING_ACCT_NO,
  field11: 'Stepping Stones 00000000',
  field12: '\n',
};

const footerLine = {
  field01: '7',
  field02: '999-999',
  field03: '            ',
  field04: '0000000000',
  field05: '0000000000',
  field06: '0000000000',
  field07: '            ',
  field08: '            ',
  field09: '000000',
  field10: '',
  field11: '\n',
};

export default async (paydayDetail) => {
  const {
    billingEndDate,
    grandTotal,
    directBillingsTotal: directBillingsGrandTotal,
    onlineScriptsTotal,
    practiceChargesGSTFree,
    practiceChargesInclGST,
    registrarFeeTotal,
    registrarOnlineScriptsTotal,
    procedureAdjustments,
  } = paydayDetail;
  const independentDoctors = getPayableIndependentDoctors(
    paydayDetail.providerList
  );

  const lineItems = [];

  const processDate = moment(billingEndDate).add(2, 'days').format('DDMMYY');
  const header = _.assign({}, headerLine);
  header.field09 = processDate;
  lineItems.push(convertLineToText(header));

  _.forEach(independentDoctors, (provider) => {
    const {
      accountName,
      accountNo,
      bsb,
      directBillingsTotal,
      grossServiceFee,
      netBillings,
      onlineScripts,
      prepayInvoice,
    } = provider;
    let creditValue = netBillings;
    if (!prepayInvoice) {
      creditValue =
        netBillings + grossServiceFee - directBillingsTotal - onlineScripts;
    }
    // Only include provider in aba file if the amount to be credited to their
    // account is greater than zero - aba upload fails if creditLine has zero
    // value.
    if (dollarsToCents(creditValue) > 0) {
      const creditLine = _.assign({}, creditProviderLine);
      creditLine.field02 = `${bsb.substring(0, 3)}-${bsb.substring(3)}`;
      creditLine.field03 = _.padStart(accountNo, 9);
      creditLine.field06 = zeroFill(dollarsToCents(creditValue), 10);
      creditLine.field07 = _.padEnd(accountName, 32);
      lineItems.push(convertLineToText(creditLine));

      if (prepayInvoice) {
        const feeLine = _.assign({}, providerFeeLine);
        feeLine.field06 = zeroFill(dollarsToCents(grossServiceFee), 10);
        lineItems.push(convertLineToText(feeLine));
      }
    }
  });

  if (practiceChargesGSTFree) {
    const gstFreeLine = _.assign({}, practiceLine);
    gstFreeLine.field06 = zeroFill(dollarsToCents(practiceChargesGSTFree), 10);
    gstFreeLine.field08 = _.padEnd('Prac Chrg GST Free', 18);
    lineItems.push(convertLineToText(gstFreeLine));
  }

  if (practiceChargesInclGST) {
    const gstLine = _.assign({}, practiceLine);
    gstLine.field06 = zeroFill(dollarsToCents(practiceChargesInclGST), 10);
    gstLine.field08 = _.padEnd('Prac Chrg Incl GST', 18);
    lineItems.push(convertLineToText(gstLine));
  }

  if (registrarFeeTotal) {
    const registrarLine = _.assign({}, practiceLine);
    registrarLine.field06 = zeroFill(
      dollarsToCents(registrarFeeTotal + registrarOnlineScriptsTotal),
      10
    );
    registrarLine.field08 = _.padEnd('Registrar Billings', 18);
    lineItems.push(convertLineToText(registrarLine));
  }

  const fileTotal = zeroFill(
    dollarsToCents(
      grandTotal +
        procedureAdjustments -
        directBillingsGrandTotal -
        onlineScriptsTotal
    ),
    10
  );
  const debitLine = _.assign({}, netDebitLine);
  debitLine.field06 = fileTotal;
  lineItems.push(convertLineToText(debitLine));

  const footer = _.assign({}, footerLine);
  footer.field05 = fileTotal;
  footer.field06 = fileTotal;
  footer.field09 = zeroFill(lineItems.length - 1, 6);
  footer.field10 = _.padEnd('', 40);
  lineItems.push(convertLineToText(footer));

  const file = new Blob(lineItems, { type: 'text/plain' });
  const fileContent = await file.text();

  const fileName = `aba-drinvoices-${processDate}.txt`;
  fileDownload(fileContent, fileName);
};
